<template>
  <div class="wameed-dashboard-page-content">

    <page-header :title="$t('nav.discounts-vendors')"/>

    <div>
      <filter-header
          :content="filterContent"
          @applyFilter="applyFilter"
          @resetFilter="resetFilter"
          @orderApplicants="orderData"

          :searchPlaceholder="$t('common.search') + ' ...'"
          :btnTitle="$t('common.filter_data')"
          :menuTitle="$t('common.order_by') + ' : '"
          :subTitle="'asdf'"
          :orderByItems=" [
        {
          name: this.$i18n.t('common.newest'),
          id: 'desc',
        },
        {
          name: this.$i18n.t('common.older'),
          id: 'asc',
        },
        {
          name: this.$i18n.t('common.alphabetical'),
          id: 'name',
        },
      ]"
      />
    </div>
    <section
        class="
        wameed-dashboard-page-content_body
        d-flex
        flex-column
        justify-content-between
        overflow-hidden
      ">
      <div class="d-flex flex-wrap flex-1 ">
        <b-col lg="12" md="12" class="px-0">

          <div class="w-table">
            <w-tables
                :noDataTitle="$t('table.no_data_title')"
                :noDataSubtitle="$t('table.no_data_subtitle')"
                v-if="getData"
                :per-page="filterData.per_page"
                :page-number="filterData.page"
                :items="getData"
                :fields="tableFields"
                :custimized-items="[

                { name: 'start_at' },
                { name: 'expire_at' },
                { name: 'category_id' },
                { name: 'discount_on' },
                { name: 'approved' },
                { name: 'status' },
                { name: 'status' },
                { name: 'action' },
              ]"
            >


              <template slot="start_at" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3">
                    {{ formatDate(data.item.start_at, 'YYYY/MM/DD') }}
                  </span>
                </div>
              </template>

              <template slot="expire_at" slot-scope="{ data }">
                <div class="d-flex">
                  <span class="text-font-main mx-3">
                    {{ formatDate(data.item.expire_at, 'YYYY/MM/DD') }}
                  </span>
                </div>
              </template>

              <template slot="category_id" slot-scope="{ data }">
                <discount-category :category="data.item.category_id"/>
              </template>
              <template slot="discount_on" slot-scope="{ data }">
                <discount-on :on="data.item.discount_on" :on-type="data.item.discount_on_type"/>
              </template>

              <template slot="approved" slot-scope="{ data }">
                <discount-approve-status-badge :status="data.item.approved"/>
              </template>

              <template slot="status" slot-scope="{ data }">
                <discount-status-badge :status="data.item.status"/>
              </template>

              <template slot="status" slot-scope="{ data }">
                <wameed-switch
                    :disabled="data.item.status===3"
                    :checked="data.item.status ===1"
                    @onChange="()=>onChange(data.item)"/>
              </template>

              <template slot="action" slot-scope="{ data }">
                <b-dropdown
                    variant="background"
                    class="table-option"
                    toggle-class="text-decoration-none rounded-10 px-2 py-2 "
                    menu-class="rounded-8"
                    no-caret
                    dropleft
                >
                  <template v-slot:button-content>
                    <vertical-dots-icon/>
                  </template>


                  <b-dropdown-item
                      :to="goToDetail(data.item.id,data.item.category_id)"
                  >
                    <span class="text-regular-14 text-font-secondary permission-show-detail">
                      <eyeon-icon class="mx-2"/>
                      {{ $t('common.show_details') }}
                    </span>
                  </b-dropdown-item>

                  <template v-if="data.item.approved==0">


                    <b-dropdown-item
                        class="permission-edit-item"
                        v-permission="[$route.meta.pagePermissions.edit]"
                        @click="acceptModal = true; currentItemData = data.item">
                    <span class="text-regular-14  text-success">
                      <true-check-icon class="mx-2 "/>
                      {{ $t('discounts.accept') }}
                    </span>
                    </b-dropdown-item>

                    <b-dropdown-item
                        class="permission-edit-item"
                        v-permission="[$route.meta.pagePermissions.edit]"
                        @click="rejectModel = true; currentItemData = data.item">
                    <span class="text-regular-14  text-danger">
                     <false-check-icon class="mx-2 "/>
                      {{ $t('discounts.reject') }}
                    </span>
                    </b-dropdown-item>

                  </template>

                </b-dropdown>
              </template>
            </w-tables>
          </div>

        </b-col>

        <b-col lg="12" md="12" class="px-0">
          <wameed-pagination
              v-if="getTotal"
              v-model="filterData.page"
              :page="filterData.page"
              :total-items="getTotal.totalItems"
              :per_page="filterData.per_page"
              :menu-title="$t('common.show_results')"
              @changePage="changePage"
              @changeCurrentPage="changeCurrentPage"
          />
        </b-col>

      </div>

    </section>


    <warning-modal
        variant="danger"
        iconName="false-check-icon"
        :visible="rejectModel"
        @close="rejectModel = false"
        @submitAction="rejectAction"
        :title="$t('discounts.modal.reject')"
        :subTitle="$t('discounts.modal.reject_desc')"
        :btnTitle="$t('discounts.reject')"
    />

    <warning-modal
        variant="success"
        iconName="true-check-icon"
        :visible="acceptModal"
        @close="acceptModal = false"
        @submitAction="acceptAction"
        :title="$t('discounts.modal.accept')"
        :subTitle="$t('discounts.modal.accept_desc')"
        :btnTitle="$t('discounts.accept')"
    />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import PageHeader from '@/components/wameed/WameedPageHeader.vue';
import FilterHeader from '@/components/wameed/WameedFilterHeader.vue';
import {WameedPagination, WameedSwitch, WameedTables as WTables} from 'wameed-ui/dist/wameed-ui.esm'
import WarningModal from "@/components/WarningModal";
import CheckValue from "@/components/checkValue";
import CheckPrice from "@/components/checkPrice";
import NameTile from "@/components/nameTile.table";
import DiscountStatusBadge from "@/views/pages/discounts/components/discountStatusBadge";
import DiscountCategory from "@/views/pages/discounts/components/discountCategory";
import DiscountOn from "@/views/pages/discounts/components/discountOn";
import {DiscountCategoryEnum} from "@/enums/discountCategory.enum";
import DiscountApproveStatusBadge from "@/views/pages/discountsVendor/components/discountApproveStatusBadge.vue";

export default {
  components: {
    DiscountApproveStatusBadge,
    DiscountOn,
    DiscountCategory,
    DiscountStatusBadge,
    NameTile,
    CheckPrice,
    CheckValue,
    WarningModal,
    PageHeader,
    FilterHeader,
    WameedPagination,
    WameedSwitch,
    WTables,
  },
  data() {
    return {
      newCoupon: false,
      showModal: false,
      rejectModel: false,
      acceptModal: false,
      currentItemData: null,
      filterData: {
        status: '',
        page: 1,
        per_page: 20,
        order_by: 'desc',
        search: '',
      },
      filterContent: [
        {
          title: this.$i18n.t('common.status'),
          key: 'status',
          id: 'id',
          name: 'title',
          type: 'text',
          selectedData: [],
          translate: true,
          data: [
            {
              id: 1,
              title: this.$i18n.t('status.active'),
            },
            {
              id: 2,
              title: this.$i18n.t('status.soon'),
            },
            {
              id: 3,
              title: this.$i18n.t('status.expire'),
            },
          ],
        },
        {
          title: this.$i18n.t('discounts.table.category'),
          key: 'category',
          id: 'id',
          name: 'title',
          type: 'text',
          selectedData: [],
          translate: true,
          data: [
            {
              id: 1,
              title: this.$i18n.t('discounts.with_code'),
            },
            {
              id: 2,
              title: this.$i18n.t('discounts.without_code'),
            },
          ],
        },
      ],
      tableFields: [
        {
          key: "index",
          label: "#",
          sortable: true,
        },

        {
          key: 'name',
          label: this.$i18n.t('discounts.table.name'),
          sortable: false,
          tdClass: 'w-2/8'
        },
        {
          key: 'vendor',
          label: this.$i18n.t('discounts.table.vendor'),
          sortable: false,
          tdClass: 'w-2/8'
        },
        {
          key: 'category_id',
          label: this.$i18n.t('discounts.table.category'),
          sortable: false,
          tdClass: 'w-1/8'
        },
        {
          key: 'discount_on',
          label: this.$i18n.t('discounts.table.on'),
          sortable: false,
          tdClass: 'w-1/8'
        },
        {
          key: 'start_at',
          label: this.$i18n.t('discounts.table.start_at'),
          sortable: false,
          tdClass: 'w-1/8'
        },

        {
          key: 'expire_at',
          label: this.$i18n.t('discounts.table.expire_at'),
          sortable: false,
          tdClass: 'w-1/8'
        },
        {
          key: 'approved',
          label: this.$i18n.t('discounts.table.approved'),
          sortable: true,
        },

        {
          key: 'status',
          label: this.$i18n.t('common.status'),
          sortable: true,
        },

        {
          key: 'status',
          label: this.$i18n.t('discounts.table.status'),
          sortable: false,
          tdClass: 'w-1/8'
        },
        {
          key: 'action',
          label: this.$i18n.t('table.options'),
          tdClass: 'w-1/8'
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getData: 'admin/discountsVendor/getData',
      getTotal: 'admin/discountsVendor/getTotal',
    }),
  },
  created() {

    this.loadData();
  },


  methods: {
    ...mapActions({
      _getData: 'admin/discountsVendor/get',
      _rejectData: 'admin/discountsVendor/reject',
      _acceptData: 'admin/discountsVendor/approve',
    }),


    goToDetail(id, type) {
      type = type === DiscountCategoryEnum.code ? 'code' : 'normal';

      return {
        name: 'discounts-vendors-detail',
        params: {lang: this.$i18n.locale, id, type},
      };
    },

    goCreatePage(type) {
      this.$router.push({
        name: 'discounts-create',
        params: {lang: this.$i18n.locale, type},
      });
    },
    onChange(item) {
      let status = 1;
      if (item.status === 1) {
        status = 0;
      }
      this.$store.dispatch('admin/discountsVendor/toggle', {id: item.id, status: status})
    },

    changeCurrentPage(item) {
      this.filterData = {
        ...this.filterData,
        per_page: item.id,
        page: 1,
      };
      this.loadData();
    },

    changePage(event) {
      this.filterData = {
        ...this.filterData,
        page: event,
      };
      this.loadData();
    },

    loadData() {
      this._getData(this.filterData);
    },

    rejectAction() {
      this.rejectModel = false
      this._rejectData(this.currentItemData.id);
    },

    acceptAction() {
      this.acceptModal = false
      this._acceptData(this.currentItemData.id);
    },
    goToUpdate(id, type) {
      type = type === DiscountCategoryEnum.code ? 'code' : 'normal';
      return {
        name: 'discounts-update',
        params: {lang: this.$i18n.locale, id, type: type},
      };
    },

    applyFilter() {
      this.filterContent.forEach(item => {
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    resetFilter() {
      this.filterContent.forEach((item, i) => {
        this.filterContent[i].selectedData = [];
        this.filterData[item.key] = item.selectedData.toString();
      });
      this.loadData();
    },


    orderData(item) {
      this.filterData = {
        ...this.filterData,
        ...item,
      };
      this.loadData();
    },
  },
};
</script>
